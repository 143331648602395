import { Pagination, Popconfirm, Table, notification } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import React from "react";
import { useNavigate } from "react-router-dom";
import API from "../../../config/API";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DELETE } from "../../../utils/apiCalls";
function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();

  const removePurity = async (item: any) => {
    const url = API.DELETE_PURITY + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response?.status) {
        Notifications["success"]({
          message: `Purity Removed successfully`,
          description: "",
        });
        props?.getPurity();
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong..",
        description: "",
      });
    }
  };
  const columns = [
    {
      title: "MetalType",
      dataIndex: "metalType",
      key: "metalType",
    },
    {
      title: "Purity",
      dataIndex: "purity",
      key: "purity",
    },

    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <Popconfirm
          placement="bottomRight"
          title={"Do you want to remove Purity?"}
          okText="Yes"
          cancelText="No"
          onConfirm={async () => await removePurity(_record)}
        >
          <RiDeleteBin6Line cursor="pointer" size={18} color={"red"} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props?.data?.length ? props.data : []}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
    </div>
  );
}

export default DataTable;
