import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import EmailLogin from "./emailLogin";
import GmailLogin from "./gmailLogin";
import PhoneLogin from "./phoneLogin";
import React from "react";
import SuccessModal from "../../components/successModal";
import { useTranslation } from "react-i18next";

function LoginScreen() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [useEmail, setUseEmail] = useState(false);
  const [successmodal, setSuccessmodal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <br /> <br />
      <Container>
        <Row>
          <Col sm={2} md={3} xs={12} lg={3} xl={4}></Col>
          <Col sm={8} md={6} xs={12} lg={6} xl={4}>
            <h2 className="LoginScreen-txt1">{t("sign_in_taitil")}</h2>
            <div className="LoginScreen-txt2">
            {t("sign_in_desc1")}
            </div>
            <div className="LoginScreen-box1">
              {useEmail ? (
                <>
                  <EmailLogin />
                  <div
                    className="LoginScreen-txt6"
                    onClick={() => setUseEmail(false)}
                  >
                    {t("Phone_Login")}
                  </div>
                </>
              ) : (
                <div>
                  <PhoneLogin />
                  <div
                    className="LoginScreen-txt6"
                    onClick={() => setUseEmail(true)}
                  >
                    {t("Email_Login")}
                  </div>
                </div>
              )}
              <br />
              <GmailLogin
                openModal={() => setSuccessmodal(true)}
                closeModal={() => setSuccessmodal(false)}
              />
              <div
                className="LoginScreen-txt4"
                onClick={() => navigation("/signup/user")}
              >
                {t("Dont_have_an_account")}{" "}
                <span className="LoginScreen-txt5">{t("Create_Account")}</span>
              </div>
            </div>
          </Col>
          <Col sm={2} md={3} xs={12} lg={3} xl={4}></Col>
        </Row>
      </Container>
      <br />
      <br />
      <SuccessModal
        visible={successmodal}
        onCancel={() => setSuccessmodal(false)}
        title="success"
        body="Account created successfully"
        onButtonClick={() => setSuccessmodal(false)}
        buttonText="Go Back"
      />
    </div>
  );
}
export default LoginScreen;
