import { useNavigate } from "react-router-dom";
import "./style.scss";
import React from "react";
import { FaStar } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
function StoreItem(props: any) {
  const navigate = useNavigate();
  return (
    <div
      className="StoreItem"
      onClick={() => navigate(`store/${props?.item?.id}/main`)}
    >
      <div>
        <img src={props?.item?.logo_upload} className="StoreItem-img" />
      </div>
      <div className="StoreItem-box1">
        <div>
          <div className="StoreItem-txt1">{props?.item?.store_name}</div>
          <div className="StoreItem-txt2">{props?.item?.business_type}</div>
        </div>

        <div className="StoreItem-txt3">
          <FaStar color="#f5da42"/> &nbsp;0.0 (0)
        </div>
      </div>
      <div>
        <div className="StoreItem-txt4">
          <IoIosMore/>
        </div>
      </div>
    </div>
  );
}

export default StoreItem;
