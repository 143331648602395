import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import ExpressIcon from "../../assets/images/EDbadge.png";
import EDfilter from "../../assets/images/EDfilter.png";
import PageHeader from "../../components/pageHeader";
import { useEffect } from "react";
import React from "react";
import API from "../../config/API";
function TermsandCond() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2">
        <PageHeader text="Terms and Conditions" />
        <h4 className="text-center">Terms and conditions</h4>
        <h5>Exchange and Refund Policies</h5>

        <h6>Exchange Policy for Gold Jewellery</h6>
        <ul>
          <li>
            Gold jewellery purchased from Red & White Gold General Trading LLC
            may be exchanged within 10 days from the invoice date, retaining its
            gold value, provided it remains in its original shape and design.
          </li>
        </ul>

        <h6>Exchange Conditions</h6>
        <ul>
          <li>
            Each purchase allows for one exchange referencing the initial
            invoice and date. Adjustments will be made for gold weight and
            making charges according to the new purchase.
          </li>
        </ul>

        <h6>Refund and Scrap Gold Policy</h6>
        <ul>
          <li>
            Requests for return, exchange, or refund beyond 10 days from
            purchase will be processed as scrap gold, valued based on current
            market conditions.
          </li>
        </ul>

        <h6>Quality Check (QC) and Validation</h6>
        <ul>
          <li>
            All exchange or buy-back transactions undergo rigorous quality
            checks by our certified laboratory, the Dubai Gold & Jewellery
            Group.
          </li>
        </ul>

        <h6>Lifetime Maintenance and Repair</h6>
        <ul>
          <li>
            We offer complimentary lifetime maintenance and repair for
            ornaments. Additional charges may apply for added gold during
            repairs, with customer approval.
          </li>
        </ul>

        <h6>Exclusions from Standard Exchange and Refund</h6>
        <ul>
          <li>
            Promotional, special occasion, Spanish collection, and 18 or 22
            carat piece rate products are non-exchangeable and non-refundable.
          </li>
        </ul>

        <h6>Gifts Issued at Purchase</h6>
        <ul>
          <li>
            Gifts received at the time of purchase must be returned with the
            product. Failure to return gifts will result in a deduction from the
            refunded amount.
          </li>
        </ul>

        <h6>Customized Orders</h6>
        <ul>
          <li>
            Customized orders must be collected within one month from the
            specified date on the voucher or order form. Original documentation
            is required for collection.
          </li>
        </ul>

        <h6>Documentation Requirement</h6>
        <ul>
          <li>
            Original invoice submission is mandatory for any jewellery return,
            exchange, or upgrade. Identification documents such as Passport,
            Emirates ID (if UAE resident), or Visit Visa copy are required as
            per UAE Federal Government regulations.
          </li>
        </ul>

        <h6>Taxation</h6>
        <ul>
          <li>
            All purchases are subject to UAE Federal Government Tax Laws, which
            are borne by the customer.
          </li>
        </ul>

        <h6>Discretion and Modification</h6>
        <ul>
          <li>
            Red & White Gold General Trading LLC reserves the right to exercise
            discretion regarding all returns and exchanges. Terms and conditions
            are subject to modification without prior notice.
          </li>
        </ul>

        <h6>Diamond Exchange Policy</h6>
        <h6>Exchange of Diamond Jewellery</h6>
        <ul>
          <li>
            Diamond jewellery can only be exchanged within the same store or
            branch upon presentation of the original invoice and certificate.
          </li>
        </ul>

        <h6>Exchange Conditions for Diamonds</h6>
        <ul>
          <li>
            A 3 to 4-day period is required for quality inspection before
            exchange, which is limited to diamond-to-diamond products.
          </li>
        </ul>

        <h6>Diamond Cashback / Refund Policy</h6>
        <h6>Lifetime Refund Policy</h6>
        <ul>
          <li>
            A lifetime refund is applicable on the day of purchase with a 40%
            deduction from the invoice amount, provided the product is in good
            condition without damages or alterations.
          </li>
        </ul>

        <h6>Conditions for Diamond Cashback</h6>
        <ul>
          <li>
            Returned diamond products must be in pristine condition without any
            damages to qualify for cashback or refund.
          </li>
        </ul>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default TermsandCond;
