import React, { useState } from "react";
import { Pagination, Popconfirm, Table, notification } from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import API from "../../config/API";
import { DELETE } from "../../utils/apiCalls";
import { BiSolidEditAlt } from "react-icons/bi";
import "./style.scss";
import { useSelector } from "react-redux";
import "./style.scss";

function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const Role = useSelector((state: any) => state.User?.user?.data?.role);
  const StoreId = useSelector((state: any) => state.User?.user?.data?.store_id);

  const handleImageClick = (imageUrl: string) => {
    setEnlargedImage(imageUrl);
  };

  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const itemDeleteHandler = async (item: any) => {
    const url = API.DELETE_BLOG + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getBlogsData(props?.page);
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const renderEllipsisText = (text: string) => (
    <div
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
      }}
    >
      {text}
    </div>
  );

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (item: string) => (
        <div className="image-preview-container">
          <img
            alt={"image"}
            src={item}
            className="banner-table-card-image"
            onClick={() => handleImageClick(item)}
          />
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: renderEllipsisText,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: renderEllipsisText,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: renderEllipsisText,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: renderEllipsisText,
    },
    {
      title: "Actions",
      key: "actions",
      width: 90,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {StoreId == record?.storeId || true ? (
            <>
              <BiSolidEditAlt
                cursor="pointer"
                size={20}
                color="grey"
                onClick={() => {
                  props?.changeType();
                  props?.getSelectedItem(record);
                  props?.openModal();
                }}
              />
              <Popconfirm
                placement="bottomRight"
                title={"Are you sure to delete?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => itemDeleteHandler(record)}
              >
                <AiTwotoneDelete cursor="pointer" size={20} color={API.COLOR} />
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        rowKey={(data: any) => data?.id}
        pagination={false}
      />
      {/* Enlarged Image Preview */}
      {enlargedImage && (
        <div className="enlarged-image-container">
          <div
            className="enlarged-image-backdrop"
            onClick={closeEnlargedImage}
          />
          <div className="enlarged-image-content">
            <img
              src={enlargedImage}
              alt="Enlarged Preview"
              className="enlarged-image"
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
