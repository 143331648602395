import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../../assets/images/women.jpg";
import img2 from "../../../assets/images/kid.jpg";
import img3 from "../../../assets/images/men.jpg";
import { useNavigate } from "react-router-dom";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";

function CategoryCard(props: any) {
  const ref: any = useRef(null);
  const navigate = useNavigate();
  const [Recent, setRecent] = useState([]);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [rightButtonClicked, setRightButtonClicked] = useState(false);
  const scroll = (ratio: any) => {
    const currentScrollLeft = ref.current.scrollLeft;
    ref.current.scrollLeft += ratio;

    if (ratio > 0 && !rightButtonClicked) {
      setRightButtonClicked(true);
    }

    if (ratio < 0 && rightButtonClicked && ref.current.scrollLeft <= 0) {
      setRightButtonClicked(false);
    }
  };

  useEffect(() => {
    function updateState() {
      const el = ref.current;
      el &&
        setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width + 50);
    }
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [Recent]);
  const data = [
    {
      image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/2e8e708834395.5602c8ec289e0.jpg",
      text: "wedding",
    },
    {
      image: img1,
      text: "female",
    },
    {
      image: img3,
      text: "men",
    },
    {
      image: img2,
      text: "kid",
    },
  ];
  return (
    <div className="Horizontal-Pscroll">
      <div className="Horizontal-Pscroll position-relative">
        <div className="Horizontal-PscrollBox" ref={ref}>
          {data?.map((item: any, idx: number) => {
            return (
              <div
                className="HomeSCreen-InformationArea"
                style={{ backgroundImage: `url(${item.image})` }}
              >
                {item?.text}
              </div>
            );
          })}
        </div>
        {hasScrollBar ? (
        <>
          {rightButtonClicked && (
            <button
              className="Horizontal-btn1 position-absolute slider-btn-left"
              onClick={() => scroll(-800)}
            >
              <MdArrowBack />
            </button>
          )}
          <button
            className="Horizontal-btn2 slider-btn-right position-absolute"
            onClick={() => scroll(800)}
          >
            <MdOutlineArrowForward />
          </button>
        </>
      ) : null}
      </div>
     
    </div>
  );
}

export default CategoryCard;
