import React from "react";
import { useTranslation } from "react-i18next";

type PageHeaderProps = {
  text: string;
};
function PageHeader({ text }: PageHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="my-4">
      <a className="text-muted" href="./">{t("Online_Shopping")}{" "}</a>{text}
    </div>
  );
}
export default PageHeader;
