import { useDispatch, useSelector } from "react-redux";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { storeCategory,storeOffers } from "../../redux/slices/categorySlice";
import { clearCart, storeCart } from "../../redux/slices/cartSlice";
import { useEffect } from "react";
import { storeSettings } from "../../redux/slices/settingsSlice";
import { logout, update } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { clearStore } from "../../redux/slices/storeSlice";

export const useLoadCategories = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response: any = await GET(API.CATEGORY, null);
        if (response?.status) {
          if (response?.data?.length) {
            dispatch(storeCategory(response?.data));
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [dispatch]);
};
export const useLoadCartItems = () => {
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCartItems = async () => {
      if (User.data?._id && Auth.auth) {
        try {
          const url = API.CART_GET_ALL;
          const cartItems: any = await GET(url, null);
          if (cartItems.status) {
            dispatch(storeCart(cartItems.data));
          }
        } catch (err) {
          // Handle errors as needed
          console.error(err);
        }
      }
    };

    fetchCartItems();
  }, [dispatch, User.data?._id]);
};

export const useGetSettings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSettings = async () => {
      const url = API.SETTINGS;
      try {
        const settings: any = await GET(url, null);
        if (settings.status) {
          dispatch(storeSettings(settings.data));
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, [dispatch]);
};
export const useRefreshUser = () => {
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signoutUser = () => {
    dispatch(clearCart(null));
    dispatch(clearStore(null));
    dispatch(logout(null));
    navigate("/");
    return;
  };
  const fetchUser = async () => {
    if (User?.data?.status != true && Auth.auth) {
      signoutUser();
    }
    if (User.data?._id && Auth.auth) {
      const url = API.USER_REFRESH;
      try {
        const user: any = await GET(url, null);
        if (user?.status) {
          if (user?.data?.status != true) {
            signoutUser();
            return;
          }
          dispatch(update(user?.data));
        }else{
          signoutUser()
        }
      } catch (err) {
        console.log("someting went wrong.");
      }
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
};


export const useLoadOffers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response: any = await GET(`${API.OFFERS}?order=ASC&page=1&take=10`, null);
        if (response?.status) {
          if (response?.data?.length) {
            dispatch(storeOffers(response?.data));
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [dispatch]);
};