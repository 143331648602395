import { Pagination, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import API from "../../config/API";

function SellusDataTable(props: any) {
  const columns = [
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Metal Type",
      dataIndex: "metalType",
      key: "metalType",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 12}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default SellusDataTable;
