import {
  Button,
  Form,
  InputNumber,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "../../../config/API";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import AdminLoading from "../../components/AdminLoading";
import { Col, Row } from "react-bootstrap";
import SettlementModal from "./settlementModal";
import { BiSolidEditAlt } from "react-icons/bi";

type props = {
  storeId: number | string | undefined;
  closeModal: Function;
  openModal: boolean;
};
function SettlementDetails({ storeId, closeModal, openModal }: props) {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [settlements, setSettlements] = useState<any[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [totalSettledPrice, setTotalSettled] = useState(0);
  const [settlementPending, setSettlementPending] = useState(0);
  const [meta, setMeta] = useState<any>({});
  const pageSize =12;
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(0);
  const totalsData = [
    {
      title: "Total Amount to Settle",
      value: Number(totalOrderPrice)?.toFixed(2),
    },
    {
      title: "Total Amount Settled",
      value: Number(totalSettledPrice)?.toFixed(2),
    },
    {
      title: "Balance to Settle",
      value: Number(limit)?.toFixed(2) ?? 0,
    },
    {
      title: "Settlement Requested/Pending",
      value: Number(settlementPending)?.toFixed(2) ?? 0,
    },
  ];
  const getSettlements = async (current: number = 1) => {
    const url =
      API.SETTLEMENT_DETAILS_HISTORY +
      storeId +
      `?order=ASC&page=${current}&take=${pageSize}`;
    try {
      if (storeId) {
        const response: any = await GET(url, null);
        if (response?.status) {
          setSettlements(response?.data);
          setLimit(response?.amountToSettle);
          setTotalOrderPrice(response?.totalOrderPrice);
          setTotalSettled(response?.totalSettledPrice);
          setSettlementPending(response?.settlementPending);
          setMeta(response?.meta);
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
        }
      }
    } catch (err) {
      Notifications["error"]({
        message: "Somethig went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const updateSettlementStatus = async (record: any) => {
    const url = API.SETTLEMENT_DETAILS_UPDATE + record?.id;
    try {
      const response: any = await PUT(url, {});
      if (response.status) {
        Notifications["success"]({
          message: "Settlement Has been Updated",
          description: "",
        });
        await getSettlements(page);
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "something went wrong.",
        description: "",
      });
    }
  };
  const changePage = async (curpage: number) => {
    await getSettlements(curpage);
    setPage(curpage);
  };
  useEffect(() => {
    getSettlements();
  }, []);
  const columns = [
    {
      title: "Amount",
      dataIndex: "paid",
      key: "paid",
      render: (item: any) => (
        <span className="fw-medium">
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },

    {
      title: "Balance", //
      dataIndex: "balance",
      key: "balance",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Total Settled", //
      dataIndex: "total",
      key: "total",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Payment Type", //
      dataIndex: "payment_type",
      key: "payment_type",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Payment Status", //
      dataIndex: "status",
      key: "status",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Settlement Date", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {contextHolder}
          {_record?.status != "success" ? (
            <Popconfirm
              placement="bottomRight"
              title={"Are you sure to Complete this settlement."}
              okText="Complete"
              cancelText="No"
              onConfirm={async () => updateSettlementStatus(_record)}
            >
              <Button type="link">
                <BiSolidEditAlt cursor="pointer" size={20} color="#a10244" />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : (
        <>
          <Row className="TotalscardContainer g-3">
            {totalsData.map((data, index) => (
              <Col md={3} key={index}>
                <div className="Totalscard">
                  <div className="title">
                    <p className="title-text">{data.title}</p>
                  </div>
                  <div className="data">
                    <p>{data.value}</p>
                    <div className="range">
                      {/* <div className="fill"></div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Table
            size="small"
            dataSource={settlements}
            columns={columns}
            pagination={{ pageSize: 12, hideOnSinglePage: true }}
            className="mt-3"
          />
        </>
      )}
      <SettlementModal
        openModal={openModal}
        storeId={storeId}
        closeModal={closeModal}
        getSettlements={getSettlements}
        limit={limit}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default SettlementDetails;
