import React from "react";
import { Row, Col } from "react-bootstrap";
import { IoBagHandleOutline, IoCardOutline } from "react-icons/io5";
import { IoCashOutline } from "react-icons/io5";
import { HiOutlineCash } from "react-icons/hi";
import { IoIosRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";

import Visa from "../../../assets/images/visa.png";
import Mster from "../../../assets/images/mastercard.png";
import Diners from "../../../assets/images/dinners.png";
import Samsu from "../../../assets/images/samsungpay.png";
import { BsBank } from "react-icons/bs";

function PaymentBox(props: any) {
  return (
    <div>
      <div className="Cart-row" style={{ padding: 0 }}>
        <div className="Cart-txt1">
          <span className="Cart-txt1Icon">
            <IoCashOutline />
          </span>
          PAYMENT MEHTOD
        </div>
      </div>
      <div className="Cart-line" />
      <br />
      <div
        className={`Cart-paymentBox ${
          props?.method === "Pay Online" ? "active" : ""
        }`}
        onClick={() => props?.onChange("Pay Online")}
      >
        <div style={{ marginRight: 20 }}>
          {props?.method === "Pay Online" ? (
            <IoMdRadioButtonOn size={25} />
          ) : (
            <IoIosRadioButtonOff size={25} />
          )}
        </div>
        <div style={{ marginRight: 10 }}>
          <IoCardOutline size={40} color="grey" />
        </div>
        <div style={{ flex: 1 }}>
          <Row>
            <Col sm={6} xs={12}>
              <div className="Cart-txt3">Pay Online</div>
            </Col>
            <Col sm={6} xs={12}>
              <div className="Cart-row" style={{justifyContent:"flex-end"}}>
                <div style={{marginRight:10}}>
                  <img src={Visa} style={{height:30}}/>
                </div>
                <div style={{marginRight:10}}>
                  <img src={Mster} style={{height:30}}/>
                </div>
                <div style={{marginRight:10}}>
                  <img src={Diners} style={{height:30}}/>
                </div>
                <div>
                  <img src={Samsu} style={{height:30}}/>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className={`Cart-paymentBox ${
          props?.method === "Cash On Delivery" ? "active" : ""
        }`}
        onClick={() => props?.onChange("Cash On Delivery")}
      >
        <div style={{ marginRight: 20 }}>
          {props?.method === "Cash On Delivery" ? (
            <IoMdRadioButtonOn size={25} />
          ) : (
            <IoIosRadioButtonOff size={25} />
          )}
        </div>
        <div style={{ marginRight: 10 }}>
          <HiOutlineCash size={40} color="grey" />
        </div>
        <div className="Cart-txt3" style={{ flex: 1 }}>
          Cash On Delivery ( COD )
        </div>
      </div>
      <div
        className={`Cart-paymentBox ${
          props?.method === "Pick From Shop" ? "active" : ""
        }`}
        onClick={() => props?.onChange("Pick From Shop")}
      >
        <div style={{ marginRight: 20 }}>
          {props?.method === "Pick From Shop" ? (
            <IoMdRadioButtonOn size={25} />
          ) : (
            <IoIosRadioButtonOff size={25} />
          )}
        </div>
        <div style={{ marginRight: 10 }}>
          <IoBagHandleOutline size={40} color="grey" />
        </div>
        <div className="Cart-txt3" style={{ flex: 1 }}>
          Pick From Shop
        </div>
      </div>
      <div
        className={`Cart-paymentBox ${
          props?.method === "Bank Transfer" ? "active" : ""
        }`}
        onClick={() => props?.onChange("Bank Transfer")}
      >
        <div style={{ marginRight: 20 }}>
          {props?.method === "Bank Transfer" ? (
            <IoMdRadioButtonOn size={25} />
          ) : (
            <IoIosRadioButtonOff size={25} />
          )}
        </div>
        <div style={{ marginRight: 10 }}>
          <BsBank size={38} color="grey" />
        </div>
        <div className="Cart-txt3" style={{ flex: 1 }}>
          Bank Transfer
        </div>
      </div>

      {props?.method === "Bank Transfer" ? (
        <div
          className={`Cart-paymentBox`}
          onClick={() => props?.onChange("Bank Transfer")}
        >
          <div className="Cart-bankDetails-box">
            <span>Banking Name : LUCKYSTAR GOLD TRADING L.L.C</span>
            <span>Account Number : 0373180410001</span>
            <span>IBAN : AE510400000373180410001</span>
            <span>SWIFT CODE : NRAKAEAKKXXX</span>
            <span>Branch : AL QUSAIS, DUBAI</span>
            <span>BANK : RAK BANK</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default PaymentBox;
