import { Radio, Tag, Tooltip, notification } from "antd";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { SingleProductContext } from "../singleProductContext";
import { useSearchParams } from "react-router-dom";

function VariantTypes() {
  const data = useContext(SingleProductContext);
  const setSelectedVariant = data?.setSelectedVariant;
  const variants = data?.variants;
  const selectedVariant = data?.selectedVariant;
  const [searchParams, setSearchParams] = useSearchParams();
  const [notificationApi, contextHolder] = notification.useNotification();
  const selectVarient = (item: any, varient: any) => {
    if (typeof setSelectedVariant == "function") {
      setSelectedVariant((current: any) => ({
        ...current,
        [item.variant]: varient.value,
      }));
      setSearchParams((params) => {
        const datas = Object.keys(selectedVariant);
        if (datas.length) {
          datas.forEach((item: any) => {
            params.set(item, selectedVariant[item]);
          });
        }
        return params;
      });
    }
  };
  const Settings = useSelector((state: any) => state.Settings.Settings);
  return (
    <>
      {contextHolder}
      {Array.isArray(variants?.variants) == true
        ? variants?.variants?.map((item: any, i: number) => {
            return (
              <div className="mt-2" key={i}>
                <div>
                  <span className="text-dark">
                    {" "}
                    <span className="text-secondary">
                      {" "}
                      {item?.variant}:
                    </span>{" "}
                    {selectedVariant[item?.variant]}
                  </span>
                </div>
                <div className="mt-2">
                  <Radio.Group
                    defaultValue={selectedVariant[item?.variant]}
                    buttonStyle="solid"
                  >
                    {item?.details?.map((varient: any, i: number) => {
                      return (
                        <Radio.Button
                          key={i}
                          value={varient.value}
                          onClick={() => selectVarient(item, varient)}
                        >
                          {varient.value}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>
                <hr />
              </div>
            );
          })
        : null}
    </>
  );
}

export default VariantTypes;
