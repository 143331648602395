import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PiShoppingCartSimple, PiUserCircle } from "react-icons/pi";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { Badge, Popover } from "antd";
import Logo from "../../config/logo.png";
import { useState } from "react";
import SideBar from "./sideBar";
import SearchBar from "./searchBar";
import ProfileMenu from "./components/profileMenu";
import LocationMobile from "./locationMobile";
import React from "react";
import API from "../../config/API";

function Mobile() {
  const User = useSelector((state: any) => state.User);
  const Cart = useSelector((state: any) => state.Cart);
  const [issharepopovervisible, setissharepopovervisible] = useState(false);

  const navigation = useNavigate();
  const [open, setOpen] = useState(false);

  const handlepopovervisiblechange = () => {
    setissharepopovervisible(!issharepopovervisible);
  };
  const OpenLink = (link: any) => {
    if (User.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="Header-mobkBox">
      <div className="Header-mobkBox2">
        <div style={{ margin: 5 }} />
        <div onClick={() => showDrawer()}>
          <HiOutlineMenuAlt2 size={28} />
        </div>
        <div style={{ margin: 5 }} />
        <div onClick={() => navigation("/")}>
          <img src={Logo} className="Header-mobLogo" alt="logo" />
        </div>
        <div style={{ flex: 1 }}></div>
        <div>
          <SearchBar />
        </div>
        <div style={{ margin: 10 }} />
        <div>
          <Badge count={Cart.items.length} color={API.COLOR}>
            <PiShoppingCartSimple size={28} onClick={() => OpenLink("/cart")} />
          </Badge>
        </div>

        <div style={{ margin: 10 }} />
        <Popover
          trigger="click"
          arrow={false}
          content={<ProfileMenu close={handlepopovervisiblechange} />}
          open={issharepopovervisible}
          onOpenChange={handlepopovervisiblechange}
        >
          <div>
            {User?.user?.data?.image ? (
              <img
              style={{marginRight:5}}
                src={User?.user?.data?.image}
                className="Header-ProfileImag"
                alt="profile"
              />
            ) : (
              <PiUserCircle size={28} />
            )}
          </div>
        </Popover>
        <div style={{ margin: 5 }} />
        <SideBar open={open} close={onClose} />
      </div>
      {/* <LocationMobile /> */}
      <SideBar open={open} close={onClose} />
    </div>
  );
}
export default Mobile;
