import { Button, Col, Form, Input, Row, notification } from "antd";
import React, { useState } from "react";
import { PUT } from "../../../../utils/apiCalls";
import API from "../../../../config/API";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../../redux/slices/userSlice";

function EditPasswordChange(props: any) {
  const [form] = Form.useForm();
  const User = useSelector((state: any) => state.User.user);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const editPassowrd = async () => {
    const values = await form.validateFields();
    const url =
      props?.type == "update"
        ? API.USER_CHANGE_PASSWORD
        : props?.type == "add"
        ? API.USER_ADDNEW_PASSWORD
        : "";
    console.log(url);
    try {
      setIsLoading(true);
      const response: any = await PUT(url, values);
      if (response?.status) {
        notificationApi.success({ message: `Password updated successfully.` });
        dispatch(update(response?.data));
        form.resetFields();
        window.location.reload();
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Something went wrong. please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form form={form}>
      <Row gutter={16}>
        <Col md={6} sm={12} xs={24}>
          <Form.Item name="oldPassword">
            <Input.Password
              size="large"
              placeholder="Enter Previous password"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Form.Item name="newPassword">
            <Input.Password
              size="large"
              placeholder="Enter New Password"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Form.Item name="confirm">
            <Input.Password
              size="large"
              placeholder="Confirm Password"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Button
            // loading={loading}
            className="Modify_btn"
            onClick={editPassowrd}
          >
            Update
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default EditPasswordChange;
