import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import React, { useEffect } from "react";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text={`${t("about")} ${API.NAME} – ${t("UAE’s_Leading_Electronics_Retailer")}`}/>
        <h3 className="border-bottom border-secondary pb-3">
        {API.NAME} – {t("Yes_Your_Electronics_Destination")}
        </h3>
        <h5>{t("TURNING_A_CONCEPT_INTO_A_REALITY")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("Since_its_inception_in_2005")}, {API.NAME} {t("has_come_a_long_way_and_achieved_many_milestones_to_become_one_of_the_leading_powerhouse_retailers_in_the_region")}. {t("UAE’s_first_ever_Big-Box_concept_started_its_journey_as_a_15,000_sq_ft_electronics_store_in_Dubai")}. {t("The_brand’s_perseverance_and_hard_work_has_paid_off_with_33_stores_in_United_Arab_Emirates")},
        {t("Bahrain_Egypt_Oman_today_and_more_in_the_offing")}
        </div>
        <h5>{t("WIDEST_CHOICE")} {t("BEST_VALUE")} {t("TRUSTED_SERVICE")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("With_over_25,000_electronic_products_and_accessories_to_choose_from_reputed_international_brands")} {API.NAME}’s {t("distinctive_style_of_enhancing_customer_lifestyle_has_left_an_indelible_mark_on_the_consumers_mind")}
        {t("making_us_a_household_name_to_be_reckoned_with")} {t("We_pride_ourselves_on_our_exemplary_service_and_product_knowledge_at_the_store_level")}
        {t("coupled_with_the_widest_range_of_electronics_available_at_the_best_value_on_display_at_one_location")}
        </div>
        <h5>{t("KEY_DIFFERENTIATORS")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("Our_24_hour")} {t("Product_not_available_claim_free")} {t("brand_promise_is_a")}
          {API.NAME} {t("assurance_to_customers_which_ensures_that_all_product_availability_concerns_are_met_with_satisfactorily")}{API.NAME} {t("ensures_that_the_products_are_authorized_to_avoid_any_post_purchase_difficulty_faced_by_customers.")} {t("The")} {API.NAME} {t("best_price_guarantee")} {t("to_customers_is_another_highlight_which_ensures_that_customers_get_the_best_value_for_any_product_across_all")} {API.NAME} {t("stores")} {t("In_case_of_customer_finding_a_product cheaper elsewhere")} {API.NAME}{t("ensures_that_the_price_is_matched")}
          {t("It_strengthens_customers_trust_in")} {API.NAME} {t("in_delivering_the_best_price_in_the_market")}
        </div>
        <h5>{t("POSITIVE_REINFORCEMENT")} </h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("Every_month_over_1_million_customers_visit_our_stores_to_make_their_purchases_and_enjoy_the_exceptional_service_and_ambience_that")} {API.NAME} {t("has_become_famous_for")} {t("This_is_further_reinforced_by_our_customers_and_vendors_endorsement_towards")} {API.NAME} {t("as_their_premier_electronics_destination_through_awards_and_accolades_that_we_have_collected_over_the_years")}
        </div>
        <ul className="bullet-list ps-4">
          <li>{t("Superbrand_2017_2016_2015_2014_2013_2012_2011")}</li>
          <li>
          {t("Retail_ME_2011_Most_admired_Retailer_of_the_year_consumer_electronics")}
          </li>
          <li>
          {t("LG_retailer_awards_2011_Best_support_and_best_in_store_display_across_select_categories")}
          </li>
          <li>
          {t("Reseller_Awards_2011_Best_Retailer_of_the_year_by_Vendors_Resellers_choice_of_channel_awards")}
          </li>
          <li>{t("MAF_Awards_2011_Anchor_Store_of_the_Year")}</li>
          <li>{t("MAF_Awards_2011_Retailer_of_the_year")}</li>
          <li>{t("Dubai_Shopping_Festival_2010_Innovation_Award")}</li>
          <li>
          {t("Middle_East_Retail_Academy_Awards_2009_Best_Retailer_for_Marketing_Promotion")}
          </li>
          <li>
          {t("Middle_East_Retail_Academy_Awards_2009_Store_Concept_of_the_Year")}
          </li>
          <li>{t("Channel_Middle_East_Award_2009_Retail_Company_of_the_year")}</li>
          <li>
          {t("VAR_Magazine_2009_Vendors_Choice_Preferred_Retailer_of_the_Year")}
          </li>
          <li>{t("ACER_Awards_2009_Power_Retailer_of_the_Year")}</li>
        </ul>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default About;
