import { Button, Form, Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { PUT } from "../../utils/apiCalls";

function UpdatePostionModal(props: any) {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({ position: props?.data?.position });
  }, [props]);
  const formSubmitHandler = async (values: any) => {
    const url = API.SUB_CATEGORY_UPDATE_POSITION + props?.data?._id;
    const obj = {
      position: Number(values?.position),
    };
    try {
      setLoading(true);
      const response: any = await PUT(url, obj);
      if (response.status) {
        Notifications["success"]({
          message: `SubCateogory Position Updated successfully`,
        });
        props?.close();
        form.resetFields();
        props?.refresh();
      } else {
        Notifications["error"]({
          message: response?.message,
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Failed to update SubCateogory position`,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        title="Update Position"
        open={props?.open}
        onOk={() => {
          form.submit();
        }}
        confirmLoading={loading}
        onCancel={() => {
          props?.close();
          form.resetFields();
        }}
        centered
        okText="Update"
      >
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={formSubmitHandler}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Position"
            name="position"
            rules={[{ required: true, message: "Please Enter Position" }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdatePostionModal;
