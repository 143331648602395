import { createSlice } from "@reduxjs/toolkit";
const LiverateSlice = createSlice({
  name: "Liverate",
  initialState: {
    liverate:{},
  },
  reducers: {
    storeLiverate: (state, action) => {
      state.liverate = action.payload;
    },
    clearLiverate: (state, action) => {
      state.liverate = {};
    },
  },
});

export const { storeLiverate, clearLiverate } = LiverateSlice.actions;
export default LiverateSlice.reducer;