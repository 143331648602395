import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import "./style.scss";
import { GET } from "../../utils/apiCalls";
import API from "../../config/API";
import { Button, Table, notification } from "antd";
import { BiSolidEditAlt } from "react-icons/bi";
import useToggle from "../../shared/hook/useToggle";
import CategoryUpdateModal from "./modal";

function LiveRate(props: any) {
  const [updateStatus, setUpdateStatus] = useState(false);
  const [liveRate, setLiveRate] = useState<any>(null);
  const [toggle, toggleModal] = useToggle(false);
  const [loadinglive, setLoadinglive] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const getLiveRate = async () => {
    try {
      const url = API.LIVERATE_GET;
      const rates: any = await GET(url, {});
      if (rates.status) {
        let ratesData: any = rates?.data?.sort((a: any, b: any) => a.id - b.id);
        setLiveRate(ratesData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLiveRate();
  }, [updateStatus]);

  const handleEditButtonClick = (id: number) => {
    setSelectedItemId(id);
    toggleModal(true);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (item: any) => (
        <img alt={"image"} src={item} className="category-table-card-image" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ask Rate",
      dataIndex: "ask",
      key: "ask",
    },
    {
      title: "Bid Rate",
      dataIndex: "bid",
      key: "bid",
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <BiSolidEditAlt
            cursor="pointer"
            size={25}
            color={API.COLOR}
            onClick={() => {
              handleEditButtonClick(_record.id);
            }}
          />
        </div>
      ),
    },
  ];

  const updateLiveRate = async () => {
    const url = API.GET_GOLD_LIVERATE;
    setLoadinglive(true);
    try {
      const response: any = await GET(url, null);
      if (response?.length) {
        Notifications["success"]({
          message: `Liverate has been updated Successfully `,
        });
        getLiveRate();
      }
    } catch (err) {
      Notifications["error"]({
        message: `Failed to update Liverate`,
      });
    } finally {
      setLoadinglive(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeader title="Live Rate">
        <Button
          type="primary"
          onClick={updateLiveRate}
          loading={loadinglive}
          className="ms-2"
        >
          Refresh Rate
        </Button>
      </PageHeader>
      <Container>
        <Table
          size="small"
          dataSource={liveRate?.length ? liveRate : []}
          columns={columns}
          pagination={false}
        />

        <CategoryUpdateModal
          open={toggle}
          modalClose={() => {
            toggleModal(false);
            setUpdateStatus((prevStatus) => !prevStatus);
          }}
          id={selectedItemId}
        />
      </Container>
    </div>
  );
}
export default LiveRate;
