import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Select, notification } from "antd";
import { BiErrorCircle } from "react-icons/bi";
import { InputOTP } from "antd-input-otp";
import { useTranslation } from "react-i18next";
import { Auth } from "../../config/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";

import API from "../../config/API";
import { POST } from "../../utils/apiCalls";

import { login } from "../../redux/slices/userSlice";
import PrefixSelector from "../../components/prefixSelector";

function PhoneLogin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setverification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [data, setdata] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [notificationApi, contextHolder] = notification.useNotification();

  const checkuser = async () => {
    try {
      let user: any = Auth.currentUser;
      if (user?.phoneNumber) {
        signOut(user);
      }
    } catch (err) {
      console.log("checkuser err", err);
    }
  };

  const LoginPhone = async (values: any) => {
    try {
      setdata(values);
      setIsLoading(true);
      checkuser();
      setError("");
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {});
      let phone = `${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError(`${t("Somehting_went_wrong")}`);
      }
      setIsLoading(false);
    } catch (err) {
      setverification(false);
      console.log("LoginPhone = = = >", err);
      setIsLoading(false);
      setError(`${t("Somehting_went_wrong")}`);
      // window.location.reload();
    }
  };

  const verifyOtp = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp.join("");
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (token) {
        await PhoneLogin(token);
      } else throw new Error(`${t("No_token_found")}`);
    } catch (err) {
      setError(`${t("Phone_Login_failed")}`);
    } finally {
      setIsLoading(false);
    }
  };

  const PhoneLogin = async (token: string) => {
    try {
      setIsLoading(true);
      let url = API.LOGIN_PHONE;
      let body = {
        code: data?.code,
        idToken: token,
      };
      var loginRes: any = await POST(url, body);
      if (loginRes.status) {
        notificationApi.success({ message: `${t("Login_Successful")}`});
        dispatch(login(loginRes));
      } else {
        setError(loginRes.message);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="PhoneLogin">
      {contextHolder}
      <div>
        <div className="LoginScreen-txt2">
          {verification
            ? `${t("Enter_OTP")} ${data?.code} ${data?.phone}`
            : `${t("sign_in_desc2")}`}
        </div>
        <br />
        <Form
          onFinish={verification ? verifyOtp : LoginPhone}
          initialValues={{ code: "+971" }}
        >
          {verification ? (
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: `${t("Input_6_digit")}`,
                },
              ]}
            >
              <InputOTP autoFocus inputType="numeric" length={6} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: `${t("Input_your_phone_number")}`,
                  },
                ]}
              >
                <Input
                  addonBefore={<PrefixSelector />}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={t("Enter_Phone_Number")}
                  type="number"
                />
              </Form.Item>
              <div id="recaptcha"></div>
            </>
          )}
          {error ? (
            <div className="LoginScreen-errortxt">
              <BiErrorCircle />
              &nbsp;
              {error} {t("Try_antoher_way")}
            </div>
          ) : null}
          <Form.Item>
            <Button
              loading={isLoading}
              block
              size="large"
              type="primary"
              htmlType="submit"
              style={{ height: 45 }}
            >
              {verification ? `${t("Login")}` : `${t("Get_OTP")}`}
            </Button>
            {error ? (
              <Button
                className="mt-2"
                // loading={isLoading}
                block
                size="large"
                style={{ height: 40 }}
                htmlType="submit"
                onClick={LoginPhone}
              >
                {`${t("Resend_OTP")}`}
              </Button>
            ) : null}
          </Form.Item>
        </Form>
        <hr />
      </div>
    </div>
  );
}
export default PhoneLogin;
