import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BgGift from "../../../assets/images/giftbox.jpg";
import BgGoldBar from "../../../assets/images/goldbar.jpg";
import BgGoldCoin from "../../../assets/images/goldcoin.jpg";
import BgSilverCoin from "../../../assets/images/silvercoin.jpg";
import { useNavigate } from "react-router-dom";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";
function InformationArea() {
  const ref: any = useRef(null);
  const navigate = useNavigate();
  const [Recent, setRecent] = useState([]);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [rightButtonClicked, setRightButtonClicked] = useState(false);
  const scroll = (ratio: any) => {
    const currentScrollLeft = ref.current.scrollLeft;
    ref.current.scrollLeft += ratio;

    if (ratio > 0 && !rightButtonClicked) {
      setRightButtonClicked(true);
    }

    if (ratio < 0 && rightButtonClicked && ref.current.scrollLeft <= 0) {
      setRightButtonClicked(false);
    }
  };

  useEffect(() => {
    function updateState() {
      const el = ref.current;
      el &&
        setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width + 50);
    }
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [Recent]);

  const data = [
    {
      id: 1,
      name: "",
      image: BgGoldBar,
      title: "Exclusive Gold Bar Offers",
      description: "Bullion Vault Deals",
      path: "/products/category?id=MQ==&type=Minted%20Bars",
    },
    {
      id: 2,
      name: "",
      image: BgGoldCoin,
      title: "Coin Deals Extravaganza",
      description: "Precious Coin Treasury",
      path: "/products/category?id=NA==&type=Gold%20Coins",
    },
    {
      id: 3,
      name: "",
      image: BgGift,
      title: "  Exclusive Gold Bar Offers",
      description: "Bullion Vault Deals",
      path: "/products/category?id=MQ==&type=Minted%20Bars",
    },
    {
      id: 4,
      name: "",
      image: BgSilverCoin,
      title: "  Exclusive Gold Bar Offers",
      description: "Bullion Vault Deals",
      path: "/products/category?id=MQ==&type=Minted%20Bars",
    },
  ];

  return (
<div className="Horizontal-Pscroll">
      <div className="Horizontal-Pscroll position-relative">
        <div className="Horizontal-PscrollBox" ref={ref}>
          {data.map((item: any) => {
            return (
              <div
              key={item?.id}
                className="HomeSCreen-InformationArea"
                style={{ backgroundImage: `url(${item?.image})` }}
              >
                <div className="HomeScreen-card-title">
               {item.title}
                </div>
                <div className="HomeScreen-card-subHead">
                  Bullion Vault Deals
                </div>
                <div
                  className="HomeScreen-card-seeMore"
                  onClick={() =>
                    navigate("/products/category?id=MQ==&type=Minted%20Bars")
                  }
                >
                  See more...
                </div>
              </div>
            );
          })}
        </div>
        {hasScrollBar ? (
          <>
            {rightButtonClicked && (
              <button
                className="Horizontal-btn1 position-absolute slider-btn-left"
                onClick={() => scroll(-800)}
              >
                <MdArrowBack />
              </button>
            )}
            <button
              className="Horizontal-btn2 slider-btn-right position-absolute"
              onClick={() => scroll(800)}
            >
              <MdOutlineArrowForward />
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default InformationArea;
