import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function SaleMessage() {
  const { t } = useTranslation();
  return (
    <div className="saleMessage-Row1 mt-4 mx-2 mx-md-0">
      <h6>
      {t("PayDay_Sale_is_LIVE")}
      </h6>
    </div>
  );
}

export default SaleMessage;
