import { Button, Col, Form, Input, Row, message, notification } from "antd";
import React, { useState } from "react";
import API from "../../../../config/API";
import { POST, PUT } from "../../../../utils/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../../redux/slices/userSlice";

function EditEmailChange(props: any) {
  const [form] = Form.useForm();
  const User = useSelector((state: any) => state.User.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  const updateEmail = async () => {
    try {
      const values = await form.validateFields();
      const { email } = values;
      const obj = {
        email,
      };
      const url = API.USER_EMAIL_UPDATE;
      setIsLoading(true);
      const Response: any = await PUT(url, obj);
      if (Response.status) {
        notificationApi.success({ message: "Email Updated Successfully" });
        dispatch(update(Response?.data));
        window.location.reload();
      } else {
        notificationApi.error({ message: Response.message });
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Emailchange-1">
      <Form form={form}>
        <div style={{ display: "flex" }}>
          <Row gutter={16}>
            <Col md={12} sm={12} xs={24}>
              <Form.Item name="email" initialValue={props?.email}>
                <Input size="large" placeholder="Enter Your Email" />
              </Form.Item>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <Button
                loading={loading}
                className="Modify_btn"
                onClick={updateEmail}
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default EditEmailChange;
