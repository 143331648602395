import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { Col, Row } from "react-bootstrap";
import { Avatar, Card, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import "./style.scss";
import StoreMainArea from "./storemain";

function SearchByStore() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState<any[]>([]);
  const [loadingStore, setLoadingStore] = useState(true);
  const [store, setStore] = useState<any>({});
  const [category, setCategory] = useState<any>("");
  const params = useParams();
  const navigate = useNavigate();
  const selectedCategory = searchParams.get("cid");
  const getStoreDetails = async () => {
    const url = API.STORE_SEARCH_GETINFO + params.store;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setStore(response.data?.store);
        setCategories(response.data?.category);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
    } finally {
      setLoadingStore(false);
    }
  };

  useEffect(() => {
    getStoreDetails();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (selectedCategory != null) {
      setCategory(selectedCategory);
    } else if (params["*"] == "main") {
      setCategory("all");
    } else if (params["*"] == "search") {
      setCategory("");
    } else {
      setCategory("");
    }
  }, [selectedCategory]);
  return (
    <div className="Screen-box mb-4">
      <Card bordered={false} className="mt-3 card-store-full">
        {loadingStore ? (
          <Skeleton avatar paragraph={{ rows: 1 }} />
        ) : (
          <Row className="mx-0">
            <Col md="3">
              <Meta
                className="mb-md-0 mb-1"
                avatar={<Avatar src={store?.logo_upload} size={75} />}
                title={store?.store_name}
                description={
                  <div>
                    <div>Everyday Store prices</div>
                    <a href={`/str/${params.store}/main`} className="searchstore-website-link">Go to Website</a>
                  </div>
                }
              />
            </Col>
            <Col md="9" className="px-0">
              <div className="d-flex gap-2 h-100 search-store-subcategories">
                <div
                  className={`search-store-tags px-3 align-self-center text-bold ${
                    category == "all" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("all");
                    navigate(`main`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  All
                </div>
                {categories?.map((item: any, index: number) => (
                  <div
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    className={`search-store-tags px-3 align-self-center text-bold ${
                      item._id == category ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      navigate(
                        `categories?cid=${item?._id}&type=${item?.name}`
                      );
                    }}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}
      </Card>
      <StoreMainArea />
    </div>
  );
}

export default SearchByStore;
