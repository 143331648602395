import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";

const PageHeader = (props: any) => {
  const categoryId = props.categoryId;
  const { t } = useTranslation();
  const Category = useSelector((state: any) => state?.Category?.categries);
  const [type, setType] = useState([]);
  const [searchparams, setSearchParams] = useSearchParams();
  const tid = searchparams.get("tid");
  useEffect(() => {
    const type = getType(Category, categoryId);
    setType(type);
  }, [categoryId]);

  const getType = (categ: any[], id: number) => {
    let subc: any = {};
    categ?.forEach((item) => {
      item?.sub_categories?.forEach((item2: any) => {
        if (item2?._id == id) {
          subc = item2;
        }
      });
    });
    return subc?.types ?? [];
  };

  const handleTabChange = (key: string) => {
    setSearchParams((searchparams) => {
      searchparams.set("tid", String(key));
      return searchparams;
    });
  };

  const tabbss = useMemo(() => {
    const array = type?.map((Icon: any, i) => {
      return {
        key: String(Icon?._id),
        label: Icon?.name,
      };
    });
    return array;
  }, [type]);

  return (
    <Row>
      <Col sm={6} xs={12}>
        <div className="productByCat-PageHeader">
          <div className="productByCat-PageHeadertxt1">
            {t("Results_for")}"{props?.title}"
          </div>
          <div className="productByCat-PageHeadertxt2">
            {`${
              props?.initial === false
                ? `${`${
                    ((props?.page > props?.meta?.pageCount
                      ? 1
                      : props?.page === 0
                      ? 1
                      : props?.page) -
                      1) *
                      props?.pageSize +
                    props?.count
                  } of ${props?.meta?.itemCount || 0}`} ${
                    props?.type === "multi" ? "Stores" : `${t("Item")}`
                  }`
                : ""
            }`}
          </div>
        </div>
      </Col>
      <Col sm={6} xs={12}>
        <div className="productByCat-PageHeaderBox">{props?.children}</div>
      </Col>
      {tabbss.length ? (
        <Tabs
          defaultActiveKey={tid ?? ""}
          onChange={(key) => handleTabChange(key)}
          items={tabbss}
          tabBarStyle={{ backgroundColor: "white" }}
          
        />
      ) : <div className="productByCat-line"/>}
    </Row>
  );
};

export default PageHeader;
