import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import SuccessModal from "../../components/successModal";
import { BiErrorCircle } from "react-icons/bi";
import "./styles.scss";
import { GET, POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { login } from "../../redux/slices/userSlice";
import { InputOTP } from "antd-input-otp";
import { Auth } from "../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useTranslation } from "react-i18next";
import SummaryCard from "./summaryCard";
import GmailLogin from "../loginScreens/gmailLogin";
import React from "react";
import PrefixSelector from "../../components/prefixSelector";
import useDebounce from "../../shared/hook/useDebounce";

function SignupScreen() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [successmodal, setSuccessmodal] = useState(false);
  const [datas, setdatas] = useState<any>({});
  const [verification, setverification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [phoneTaken, setPhoneTaken] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailTaken, setEmailTaken] = useState(false);
  const debouncePhone = useDebounce(phoneNumber, 300);
  const debounceEmail = useDebounce(emailId, 300);
  const LoginPhone = async (values: any) => {
    try {
      setdatas(values);
      setIsLoading(true);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {});
      let phone = `${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError(`${t("Something_went_wrong")}`);
      }
    } catch (err: any) {
      setverification(false);

      if (err?.message?.includes("invalid-phone")) {
        setError(`${t("Invalid_Phone_Number")}`);
      } else {
        setError(`${t("Somehting_went_wrong")}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp.join("");
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (token) {
        Signup(token);
      }
    } catch (err: any) {
      if (err?.message?.includes("code-expired")) {
        setError(`${t("Otp_has_been_Expired")}`);
      } else if (err?.message?.includes(`${t("invalid_verification_code")}`)) {
        setError(`${t("Invalid_OTP_Please_check")}`);
      } else {
        setError(`${t("Somehting_went_wrong")}`);
      }
      setIsLoading(false);
    } finally {
    }
  };

  const Signup = async (token: string) => {
    try {
      let url = API.SIGNUP;
      let body = {
        email: datas.email,
        password: datas.password,
        first_name: datas?.firstname,
        last_name: datas?.lastname,
        countrycode: datas?.code,
        idToken: token,
      };
      var signupRes: any = await POST(url, body);
      if (signupRes.status) {
        dispatch(login(signupRes));
        setSuccessmodal(true);
        setTimeout(() => {
          setSuccessmodal(false);
        }, 2000);
      } else {
        setError(signupRes.message);
      }
    } catch (err) {
      setError(`${t("Somehting_went_wrong")}`);
    } finally {
      setIsLoading(false);
    }
  };
  const checkPhoneNumber = async () => {
    const url = API.USER_CHECK_PHONE + phoneNumber;
    if (phoneNumber?.length > 8) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setPhoneTaken(response?.data);
        } else {
          setPhoneTaken(false);
        }
      } catch (err) {
        setPhoneTaken(false);
      }
    }
  };
  const isValidEmail = (email: string) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkEmailId = async () => {
    const url = API.USER_CHECK_EMAIL + emailId;
    if (isValidEmail(emailId)) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setEmailTaken(response?.data);
        } else {
          setEmailTaken(false);
        }
      } catch (err) {
        setEmailTaken(false);
      }
    } else {
      setEmailTaken(false);
    }
  };
  useEffect(() => {
    checkPhoneNumber();
  }, [debouncePhone]);
  useEffect(() => {
    checkEmailId();
  }, [debounceEmail]);
  return (
    <div className="Screen-box">
      <br /> <br />
      <h2 className="signupScreen-txt1">{t("Create_your_account")}</h2>
      <div className="signupScreen-txt2">{t("singup_desc1")}</div>
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <div className="LoginScreen-box1">
              {verification ? (
                <>
                  <SummaryCard data={datas} />
                  <div className="signupScreen-txt2">{t("Enter_OTP")}</div>
                  <Form onFinish={verifyOtp}>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: `${t("Input_6_digit")}`,
                        },
                      ]}
                    >
                      <InputOTP autoFocus inputType="numeric" length={6} />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      {t("Verify")}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <div className="signupScreen-txt2">{t("singup_desc2")}</div>
                  <br />
                  <Form
                    onFinish={LoginPhone}
                    initialValues={{ code: "+91" }}
                    layout="vertical"
                  >
                    <Row>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"firstname"}
                          rules={[
                            {
                              required: true,
                              message: `${t("Please_enter_firstname")}`,
                            },
                            {
                              max: 30,
                              message: `${t("Firstname_is_too_long")}`,
                            },
                          ]}
                        >
                          <Input placeholder={t("First_Name")} size="large" />
                        </Form.Item>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"lastname"}
                          rules={[
                            {
                              required: true,
                              message: `${t("Please_enter_lastname")}`,
                            },
                            {
                              max: 30,
                              message: `${t("Lastname_is_too_long")}`,
                            },
                          ]}
                        >
                          <Input placeholder={t("Last_Name")} size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {phoneTaken ? (
                      <p className="text-danger my-0 py-0">
                        {t("This_Phone_number")}
                      </p>
                    ) : null}
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_input_your_phone_number")}`,
                        },
                        {
                          max: 16,
                          message: `${t("Phone_Number_is_Invalid")}`,
                        },
                        () => ({
                          validator(_, value) {
                            if (phoneTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve(
                              `${t("Phone_Number_available")}`
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        addonBefore={<PrefixSelector />}
                        style={{ width: "100%" }}
                        size="large"
                        placeholder={`${t("Enter_Phone_Number")}`}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onKeyPress={(e) => {
                          const isNumber = /^[0-9]*$/;
                          if (!isNumber.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(ph) => setPhoneNumber(ph.target.value)}
                      />
                    </Form.Item>

                    {emailTaken ? (
                      <p className="text-danger my-0 py-0">
                        {t("This_Email_Id")}
                      </p>
                    ) : null}
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_enter_your_email_id")}`,
                        },
                        {
                          type: "email",
                          message: `${t("Please_enter_valid_email_id")}`,
                        },
                        {
                          max: 60,
                          message: `${t("Email_id_is_Invalid")}`,
                        },
                        () => ({
                          validator(_, value) {
                            if (emailTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve(`${t("EmailId_available")}`);
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder={t("Enter_Email_Address")}
                        size="large"
                        onChange={(em) => setEmailId(em?.target?.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_input_your_password")}`,
                        },
                        {
                          min: 8,
                          message: `${t(
                            "Password_must_be_minimum_8_characters"
                          )}`,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("Enter_Password")}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"confirm"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_confirm_your_password")}`,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(`${t("The_new_password_not_match")}`)
                            );
                          },
                        }),
                      ]}
                      dependencies={["password"]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("Confir_Password")}
                      />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <div id="recaptcha"></div>
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      {t("Create_Account")}
                    </Button>
                  </Form>
                </>
              )}
              <br />
              <GmailLogin
                closeModal={() => setSuccessmodal(false)}
                openModal={() => setSuccessmodal(true)}
              />
              <br />
              <div
                className="signupScreen-txt4"
                onClick={() => navigation("/login")}
              >
                {t("Already_have_an_account")}{" "}
                <span className="signupScreen-txt5">{t("Login")}</span>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
      <br />
      <br />
      {successmodal ? (
        <SuccessModal
          visible={successmodal}
          onCancel={() => setSuccessmodal(false)}
          title="Success"
          body="Congartulation Account created successfully"
          onButtonClick={() => setSuccessmodal(false)}
          buttonText="Start Purchasing"
        />
      ) : null}
    </div>
  );
}
export default SignupScreen;
