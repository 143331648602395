import { PiAddressBook } from "react-icons/pi";
import { Collapse, Drawer } from "antd";
import { useSelector } from "react-redux";
import DrawerProfile from "./drawerProfile";
import { LiaStarSolid } from "react-icons/lia";
import { MdFormatListBulleted } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { BsBookmarkHeart } from "react-icons/bs";
import { ImPriceTag } from "react-icons/im";
import { AiFillGold, AiOutlineUnorderedList } from "react-icons/ai";
import LanguageSelector from "./components/languageSelector";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";

function SideBar(props: any) {
  const { t } = useTranslation();
  const Category = useSelector((state: any) => state.Category.categries);
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const { Panel } = Collapse;
  const navigation = useNavigate();
  const iconSize = 22;

  const OpenLink = (link: any) => {
    if (Auth.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
    props?.close();
  };

  const StoreLink = () => {
    if (Auth?.auth) {
      switch (User?.data?.type) {
        case "user":
          navigation("/seller");
          break;
        case "seller":
          navigation(`/auth/check_store?id=${Auth?.user?.data?.store_id}`);
          break;
        case "admin":
          navigation(`/auth/check_store?id=${Auth?.user?.data?.store_id}`);
          break;
      }
    } else {
      navigation("/seller");
    }
    props?.close();
  };

  const CategoryLink = (categoryId: string, categoryName: string) => {
    const encodedCategoryId = window.btoa(categoryId);
    const path = `/products/category?id=${encodedCategoryId}&type=${categoryName}`;

    navigation(path);
    props?.close();
  };

  return (
    <Drawer
      title={<DrawerProfile />}
      placement={"left"}
      width={280}
      onClose={props?.close}
      open={props?.open}
      key={"left"}
      closeIcon={false}
    >
      <div style={{ marginTop: -20, marginLeft: -10 }}>
        <LanguageSelector />
        <div className="Sidebar-itemBox" onClick={() => OpenLink("/liveRates")}>
          <ImPriceTag size={iconSize} color="#757575" />
          <span>Today's Price</span>
        </div>
        <div
          className="Sidebar-itemBox Sidebar-digitalGold"
          onClick={() => window.open("https://digigold.luckystargold.com/", "_blank")}
        >
          <AiFillGold color="#ffbf42" size={iconSize} />
          <span>Digital Gold</span>
        </div>
        {Auth.auth ? (
          <>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/home");
              }}
            >
              <CgProfile size={iconSize} color="#757575" />
              <span>{t("Profile")}</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/orders");
              }}
            >
              <AiOutlineUnorderedList size={iconSize} color="#757575" />
              <span>{t("My_Orders")}</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/favourites");
              }}
            >
              <BsBookmarkHeart size={iconSize} color="#757575" />
              <span>{t("Wishlist")}</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/address");
              }}
            >
              <PiAddressBook size={iconSize} color="#757575" />
              <span>{t("My_Address")}</span>
            </div>
          </>
        ) : null}
        <hr />
        <div className="Sidebar-txt1">{t("Categories")}</div>
        <Collapse
          className="Sidebar-collapse"
          bordered={false}
          expandIconPosition="end"
        >
          {Category?.map((item: any, idx: number) => {
            return (
              <Panel header={item?.name} key={idx}>
                {item?.sub_categories?.map((subCat: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="Sidebar-txt2"
                      onClick={() => CategoryLink(subCat._id, subCat.name)}
                    >
                      {subCat?.name}
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
        <hr />
        <div className="Sidebar-itemBox" onClick={() => OpenLink("/about")}>
          <MdFormatListBulleted size={iconSize} color="#757575" />
          <span>{t("About_us")}</span>
        </div>
        <div className="Sidebar-itemBox">
          <LiaStarSolid size={iconSize} color="#757575" />
          <span>{t("Rate_us")}</span>
        </div>
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            OpenLink("/contact");
          }}
        >
          <IoIosHelpCircleOutline size={iconSize} color="#757575" />
          <span>{t("Need_Help")}</span>
        </div>
      </div>
    </Drawer>
  );
}
export default SideBar;
