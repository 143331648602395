import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { Card, Skeleton } from "antd";
import StoreMainArea from "./components/storemain";
import { Container } from "react-bootstrap";
import { CiYoutube } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram, FaTwitter } from "react-icons/fa6";
import StoreBanners from "./components/StoreBanners";
import { configConsumerProps } from "antd/es/config-provider";

export default function StoreHome() {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("qs") || "";
  const storeId = searchParams.get("id") || "";
  const [products, setProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingStore, setLoadingStore] = useState(true);
  const [store, setStore] = useState<any>(null);
  const [category, setCategory] = useState<any>("");
  const params = useParams();
  const navigate = useNavigate();
  const selectedCategory = searchParams.get("cid");
  const [Banner, setBanners] = useState([]);

  const getStoreDetails = async () => {
    const url = API.STORE_SEARCH_GETINFO + params.id;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setStore(response.data?.store);
        setCategories(response.data?.category);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
    } finally {
      setLoadingStore(false);
    }
  };

  useEffect(() => {
    getStoreDetails();
    getBanners();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (selectedCategory != null) {
      setCategory(selectedCategory);
    } else if (params["*"] == "main") {
      setCategory("all");
    } else if (params["*"] == "search") {
      setCategory("");
    } else {
      setCategory("");
    }
  }, [selectedCategory]);
  const getBanners = async () => {
    const url = API.STORE_SEARCH_BANNERS + `${params.id}`; // to get all the banner of a single store
    try {
      const banners: any = await GET(url, null);
      if (banners.status) {
        setBanners(banners.data);
      }
    } catch (err) {
      console.log("failed to get banneers");
    }
  };
  return (
    <Card bordered={false} className="storehome">
      {loadingStore ? (
        <Skeleton avatar paragraph={{ rows: 1 }} />
      ) : (
        <div className="storehome">
          {" "}
          <Header store={store} categories={categories} category={category} />
          <Container className="storehome-main">
            {/* <div className="storehome-firstsection">
              <div className="storehome-firstsection-text">
                Everyday Store prices
              </div>
              
            </div> */}
            <br />
            {params["*"] == "main" ? <StoreBanners data={Banner} /> : <></>}
            <div className="storehome-categories">
              <div className="storehome-categories-card-header">
                What's on your Mind
              </div>
              <div className="storehome-categories-card-body">
                {categories?.map((item: any) => (
                  <div
                    className="store-categories-container"
                    onClick={() => {
                      navigate(
                        `categories?cid=${item?._id}&type=${item?.name}`
                      );
                    }}
                  >
                    <div
                      className={`store-categories-single-img ${
                        item?._id == category ? "active" : ""
                      }`}
                    >
                      <img src={item?.image} alt="" />
                    </div>
                    <div
                      className={`store-categories-single-title ${
                        item?._id == category ? "active" : ""
                      }`}
                    >
                      {item?.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <StoreMainArea />
          </Container>
          <Footer store={store} />
        </div>
      )}
    </Card>
  );
}
