import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import useToggle from "../../../shared/hook/useToggle";
import DataTable from "./datatable";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/API";
import BrandAddModal from "./addBrandmodal";

function Brands() {
  const [toggle, toggleModal] = useToggle(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [Notifications, contextHolder] = notification.useNotification();

  const getPurity = async () => {
    const url = API.GET_BRAND;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setData(response.data);
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Something went wrong..`,
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPurity();
  }, []);
  return (
    <>
      {contextHolder}
      <Button
        onClick={() => {
          toggleModal(true);
        }}
        type="primary"
      >
        Add Brand +
      </Button>
      <DataTable data={data} loading={loading} getPurity={getPurity} />
      <BrandAddModal
        open={toggle}
        close={() => toggleModal(false)}
        refresh={() => getPurity()}
      />
    </>
  );
}

export default Brands;
