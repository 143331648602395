import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../config/logo.png";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import Clock from "react-live-clock";
import moment from "moment";
import { useSelector } from "react-redux";
function LiveRates() {
  const Liverate = useSelector((state: any) => state.Liverate);
  console.log("Liverate");
  console.log(Liverate);
  console.log("Liverate");

  const [full, setFull] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 40);
  }, []);


  const enterFullscreen = () => {
    const elem: any = tableRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleFullscreen = () => {
    setFull(!full);
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  return (
    <div className="LiveRates-box" ref={tableRef}>
      <Container>
        <div className="LiveRates-box2">
          {full ? (
            <div className="LiveRates-logo">
              <img src={Logo} />
            </div>
          ) : null}

          <Row>
            <Col sm={6} xs={12}>
              <div className="LiveRates-header">
                <div className="LiveRates-txt1">Today's Gold Rate</div>
                <div style={{ flex: 1 }}></div>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div className="LiveRates-header">
                <div className="LiveRates-txt2">
                  {moment().format("dddd , DD MMMM YYYY")} &nbsp;|&nbsp;{" "}
                  <span className="LiveRates-txt4">
                    <Clock format={"HH:mm:ss"} interval={1000} ticking={true} />
                  </span>
                </div>
                <div className="LiveRates-txt3" onClick={handleFullscreen}>
                  {full ? <MdFullscreenExit /> : <MdFullscreen />}
                </div>
              </div>
            </Col>
          </Row>

          <div className="LiveRates-card1">
            <table className="LiveRates-table">
              <thead>
                <tr>
                  <td className="LiveRates-label">Commmodity</td>
                  <td>Weight</td>
                  <td>Price ( AED )</td>
                </tr>
              </thead>
            </table>
          </div>

          {Liverate?.liverate?.map((rate: any) => {
            return (
              <>
                <div className="LiveRates-card2">
                  <table className="LiveRates-table">
                    <tbody>
                      <tr>
                        <td className="LiveRates-label">{rate?.name}</td>
                        <td>1 Gram</td>
                        <td>{rate?.ask}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
          <div>
            <div className="LiveRates-card2" style={{ height: 300 }}>
              {/* <iframe
                src="https://goldbroker.com/widget/live/XAU?height=300&currency=AED"
                width="100%"
                height="300"
                style={{ backgroundColor: "#FFF" }}
              ></iframe> */}
              <iframe
                src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_2f642&symbol=FOREXCOM%3AXAUUSD&interval=60&hidesidetoolbar=1&symboledit=1&saveimage=0&toolbarbg=f1f3f6&studies=%5B%5D&hideideas=1&theme=White&style=10&timezone=Etc%2FUTC&hidevolume=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&utm_medium=widget&utm_campaign=chart&utm_term=FOREXCOM%3AXAUUSD"
                width="100%"
                height="300"
              ></iframe>
            </div>
          </div>
          <br />
        </div>
      </Container>
    </div>
  );
}

export default LiveRates;
