import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, notification, Form, Input, Tag } from "antd";
import API from "../../../config/API";
import { COMPRESS_IMAGE, PUT } from "../../../utils/apiCalls";
import { Col, Row } from "react-bootstrap";
import ImagePicker from "../../components/ImagePicker";
import Cropper, { ReactCropperElement } from "react-cropper";
import moment from "moment";

const EditProductVariantModal = (props: any) => {
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const variant = props?.selectedVariant;
  const cropperRef = useRef<ReactCropperElement>(null);
  const [form] = Form.useForm();
  const [image, setImage] = useState<any>({});
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [cropimage, setCropImage] = useState(false);
  const fileInputRef = useRef(null);
  const aspectRatio = 1 / 1;
  const closeModal = () => {
    props?.closeModal();
    setImage({});
    setCropImage(false);
    form.resetFields();
    setCroppedImage(null);
  };
  const onFinish = async (values: any) => {
    const url = API.PRODUCT_UPDATE_VARIANTS + variant?.id;
    let imgUrl = "";
    try {
      setLoading(true);
      if (croppedImage?.file) {
        const imageUrl: any = await COMPRESS_IMAGE(croppedImage?.file);
        imgUrl = imageUrl?.Location;
      }
      const obj = { ...values, image: imgUrl };
      const response: any = await PUT(url, obj);
      if (response?.status) {
        closeModal();
        if (typeof props?.getProductDetails == "function") {
          props?.getProductDetails();
        }
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas: HTMLCanvasElement | null =
        cropperRef.current.cropper.getCroppedCanvas({
          fillColor: "#fff",
        });
      if (canvas) {
        const croppedData = canvas.toDataURL("image/jpeg");
        const ImageBlob = await fetch(croppedData).then((r) => r.blob());
        let name = moment(new Date()).unix();
        let file = new File([ImageBlob], name + "N.jpg");
        const url = URL.createObjectURL(file);
        setCroppedImage({ file, url });
        setCropImage(false);
        try {
          // const values = await form.validateFields();
          // formSubmitHandler(values);
        } catch (error) {
          console.error("Form validation failed:", error);
        }
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      sku: variant?.sku,
      barcode: variant?.barcode,
      units: Number(variant?.units),
      price: variant?.price,
    });
  }, [props?.openModal]);
  return (
    <>
      {contextHolder}
      <Modal
        open={props?.openModal ?? false}
        onCancel={closeModal}
        centered
        title={`Edit Variant (${props?.product?.name ?? ""})`}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="activate"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Update Product
          </Button>,
        ]}
      >
        {cropimage ? (
          <div className="mt-2">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setImage({
                    file: selectedFile,
                    url: URL.createObjectURL(selectedFile),
                  });
                }
              }}
            />
            <Cropper
              ref={cropperRef as React.RefObject<ReactCropperElement>}
              src={image?.url}
              style={{ height: 400, width: "100%" }}
              aspectRatio={aspectRatio}
              guides={true}
            />
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Button type="primary" onClick={handleCrop}>
                Crop Image
              </Button>
              <Button
                style={{ marginLeft: 16 }}
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as any).click();
                  }
                }}
              >
                Choose Another Image
              </Button>
            </div>
          </div>
        ) : (
          <ImagePicker
            size={"lagre"}
            fileURL={
              croppedImage?.url ? croppedImage?.url : variant?.image ?? ""
            }
            onChange={(file: any) => {
              setImage(file);
              setCropImage(true);
            }}
          />
        )}

        {Array.isArray(variant?.combination) == true ? (
          <div>
            {variant?.combination?.map((item: any) => {
              return (
                <Tag bordered={false}>
                  <span>{`${item.variant}: ${item?.value} `}</span>
                </Tag>
              );
            })}
          </div>
        ) : null}
        <Form
          name="basic"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col md="6">
              <Form.Item label="Barcode" name="barcode">
                <Input style={{ width: "100%" }} disabled />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item label="SKU" name="sku">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item
                label="Units"
                name="units"
                rules={[{ required: true, message: "Please Input Units" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Please input Price" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};

export default EditProductVariantModal;
