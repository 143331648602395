import { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import { Checkbox, Form, Input, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import React from "react";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function Newsletter() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onFinish(values: any): void {
    throw new Error("Function not implemented.");
  }

  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2">
        <PageHeader text={t("newsletter")} />
        <Row className="d-flex justify-content-center ftscreen-fnt m-2">
          <Col md={5} className="m-5">
            <Form onFinish={onFinish}>
              <h3 className="text-center mb-3">{t("Subscribe_now")}</h3>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: `${t("Please_enter_your_First_Name")}`,
                  },
                ]}
              >
                <Input placeholder={t("First_Name")} />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: `${t("Please_enter_your_Last_Name")}`,
                  },
                ]}
              >
                <Input placeholder={t("Last_Name")} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: `${t("Please_enter_your_Email")}`,
                  },
                ]}
              >
                <Input type="email" placeholder={t("Enter_email")} />
              </Form.Item>
              <Form.Item
                name="subscribe"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox>
                  {t("Yes_I_would")}
                  {API.NAME}.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100">
                  {t("SUBSCRIBE")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default Newsletter;
