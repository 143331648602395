import React, { useEffect, useState, useRef } from "react";
import { Alert, Modal, Spin } from "antd";
import API from "../../../config/API";
import "./style.scss";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { LocationType } from "../../../shared/types/types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocation,
  storeLocation,
} from "../../../redux/slices/locationSlice";
import { LoadingOutlined } from "@ant-design/icons";
import AutoCompleteLocationInput from "./AutoCompleteLocationInput";
import { IoLocationOutline } from "react-icons/io5";
import MapPicker from "react-google-map-picker";

const ChooseLocationModal = (props: any) => {
  const dispatch = useDispatch();
  const inputRef: any = useRef(null);
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const [zoom, setZoom] = useState(10);

  const [defaultLocation, setDefaultLocation] = useState({
    lat: LocationDetails?.latitude || 25.204849,
    lng: LocationDetails?.longitude || 55.270782,
  });

  function handleChangeLocation(lat: any, lng: any) {
    getLocation(lat, lng);
    setDefaultLocation({ lat: lat, lng: lng });
  }

  function handleLocationClick() {
    try {
      setIsLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          getLocation(latitude, longitude);
          setDefaultLocation({ lat: latitude, lng: longitude });
        }, handleError);
      } else {
        handleError({ message: "not supported" });
      }
    } catch (err) {
      handleError({ message: "not supported" });
    }
  }

  const getLocation = (lat: number, long: number) => {
    try {
      setError(null);
      const locationObj: any = {};
      locationObj.latitude = lat;
      locationObj.longitude = long;
      const url =
        API.GET_LOCATION + `?latlng=${lat},${long}&key=${API.GGL_TOKEN}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          handleError({ message: "Failed to get your location" });
          return;
        })
        .then((response) => {
          if (response?.status == "OK") {
            response.results?.map((item: any) => {
              if (item.types?.includes("country")) {
                locationObj.country = item.formatted_address;
              }
              if (item.types?.includes("postal_code")) {
                locationObj.postal_code = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_1")) {
                locationObj.state = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_3")) {
                locationObj.district = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_4")) {
                locationObj.taluk = item.formatted_address;
              }
              if (item.types?.includes("plus_code")) {
                locationObj.plus_code = item.formatted_address;
              }
              if (item.types?.includes("street_address")) {
                locationObj.street_address = item.formatted_address;
              }
              if (item.types?.includes("route")) {
                locationObj.route = item.formatted_address;
              }
            });
            dispatch(storeLocation(locationObj));
            setIsLoading(false);
            props?.modalClose(false);
          } else {
            handleError({
              message: `Request failed ,reason:${response.status}`,
            });
            dispatch(clearLocation(null));
          }
        })
        .catch((err) => {
          handleError({ message: `Failed to get Location.` });
        });
    } catch (err) {
      handleError({ message: `Failed to get Location.` });
    }
  };

  const handleError = (error: any) => {
    setError(`Unable to get your Location. ${error.message}`);
    setIsLoading(false);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (props?.open == true) {
        inputRef?.current?.focus();
      }
    }, 10);
  };

  useEffect(() => {
    focusInput();
  }, [props?.open]);

  console.log("LocationDetails", LocationDetails);

  return (
    <Modal
      title={" "}
      open={props?.open}
      onCancel={() => props?.modalClose(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <div className="current-location-txt4">
        Select a location for delivery
      </div>
      <div className="current-location-txt5">
        Choose your address location to see product availability and delivery
        options
      </div>
      <br />
      <MapPicker
        zoom={zoom}
        style={{ height: "200px" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={(newZoom) => setZoom(newZoom)}
        apiKey={API.GGL_TOKEN}
        defaultLocation={defaultLocation}
      />
      <br />
      <div className="current-location-box" onClick={handleLocationClick}>
        <div>
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            <FaLocationCrosshairs size={24} />
          )}
        </div>
        <div className="current-location-box2">
          <div className="current-location-txt2">Use My Current Location</div>
        </div>
      </div>

      <br />
      <AutoCompleteLocationInput
        ref={inputRef}
        size="large"
        onCancel={() => {
          setError(null);
          props?.modalClose(false);
        }}
      />
      {LocationDetails.latitude ? (
        <>
          <div>Current Location</div>
          <div className="current-location-box">
            <div>
              <IoLocationOutline size={30} style={{ marginRight: 10 }} />
            </div>
            <div>
              <div className="current-location-txt1">
                {LocationDetails?.country}
              </div>
              <div className="current-location-txt3">
                {LocationDetails.latitude
                  ? LocationDetails.full_address
                    ? LocationDetails?.full_address
                    : LocationDetails.postal_code
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
      {error ? (
        <Alert
          style={{ marginTop: 20 }}
          type="error"
          message={<div style={{ color: "red", fontSize: 16 }}>{error}</div>}
        />
      ) : null}
    </Modal>
  );
};

export default ChooseLocationModal;
