import React, {  useRef, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input, notification } from "antd";
import "cropperjs/dist/cropper.css";
import API from "../../../config/API";
import { POST } from "../../../utils/apiCalls";
const BrandAddModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const formSubmitHandler = async (values: any) => {
    const url = API.ADD_BRAND;
    try {
      setIsLoading(true);
      const response: any = await POST(url, values);
      if (response?.status) {
        Notifications["success"]({
          message: "Brand Added successfully",
        });
        props?.close();
        form.resetFields();
        props?.refresh();
      } else {
        Notifications["error"]({
          message: response?.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: `Failed to Add Brand.."}`,
        description: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={`Add Brand`}
      open={props?.open}
      okText="Add"
      centered
      width={500}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
      onCancel={() => {
        props?.close();
        form.resetFields();
      }}
    >
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
        <Form.Item
          label="Type"
          name={"type"}
          rules={[
            {
              required: true,
              message: "Please Enter Type",
            },
          ]}
        >
          <Input placeholder="Type" />
        </Form.Item>

        <Form.Item
          label="Name"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please Enter Brand Name",
            },
          ]}
        >
          <Input placeholder="Name"  />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BrandAddModal;
