import API from "../../config/API";
import DataTable from "./DataTable";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import AdminLoading from "../components/AdminLoading";
import PageHeader from "../components/PageHeader";
import { Button, notification } from "antd";
import SubCategoryUpdateModal from "./subCategoryModal";
import useToggle from "../../shared/hook/useToggle";
import React from "react";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";
import UpdatePostionModal from "./updatePositionModal";

type ModalState = "add" | "update";

function SubCategories() {
  const [subCategory, setSubCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toggle, toggleModal] = useToggle(false);
  const [type, setType] = useState<ModalState>("add");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [searching, setSearching] = useState(false);
  const [reset, setReset] = useState(false);
  const [search, setSearch] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();
  const [positionModal, togglePosition] = useToggle(false);
  const [positionSelected, setPositionSelected] = useState<any>({});

  useEffect(() => {
    getSubCategory();
  }, []);

  const getSubCategory = async (page: number = 1, search: string = "") => {
    try {
      let url =
        API.SUB_CATEGORY +
        `?order=ASC&page=${page}&take=${pageSize}&search=${search}`;
      let response: any = await GET(url, null);
      if (response?.status) {
        setSubCategory(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = async (page: number, take: number) => {
    await getSubCategory(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  const receiveData = (_record: any) => {
    setSelectedItem(_record);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getSubCategory(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  return (
    <>
      {contextHolder}
      <PageHeader
        title={`SubCategories ${isLoading ? "" : `(${meta.itemCount ?? 0})`}`}
      >
        <Button
          onClick={() => {
            toggleModal(true);
            setType("add");
          }}
          type="primary"
        >
          Add Subcategory +
        </Button>
        <Search
          placeholder="Search on Category Name"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            loading={isLoading}
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getSubCategory();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
      </PageHeader>
      {isLoading ? (
        <AdminLoading />
      ) : (
        <DataTable
          data={subCategory}
          openModal={() => toggleModal(true)}
          changeType={() => setType("update")}
          receiveData={receiveData}
          getSubCategory={getSubCategory}
          changePage={changePage}
          page={page}
          meta={meta}
          pageSize={pageSize}
          openPosition={() => togglePosition(true)}
          setPosition={setPositionSelected}
        />
      )}
      <SubCategoryUpdateModal
        open={toggle}
        modalClose={() => toggleModal(false)}
        data={selectedItem}
        getSubCategory={getSubCategory}
        type={type}
      />
      <UpdatePostionModal
        open={positionModal}
        close={() => togglePosition(false)}
        data={positionSelected}
        refresh={() => getSubCategory(page, search)}
      />
    </>
  );
}
export default SubCategories;
