import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import '../style.scss'; // Ensure you have this CSS file in your project

const Zoom = (props: any) => {
    const imageUrl =
        props.activeVariant?.status === true && props.activeVariant?.variant?.image
            ? props.activeVariant?.variant?.image
            : props.productInfo?.image;
    return (
        <div>
            <div>
                <ReactImageMagnify
                    {...{
                        smallImage: {
                            isFluidWidth: true,
                            src: imageUrl,
                            width: 1500,
                            height: 1500,
                        },
                        largeImage: {
                            src: imageUrl,
                            width: 1800,
                            height: 1800,
                        },
                        enlargedImageContainerDimensions: {
                            width: "100%",
                            height: "100%",
                        },
                        lensStyle: { backgroundColor: 'rgba(0,0,0,.6)', zIndex: 1000 },
                        enlargedImageContainerStyle: { zIndex: 1000 },
                    }}
                />

            </div>
        </div>
    );
};

export default Zoom;
// import React from 'react'

// function reactZom() {
//   return (
//     <div>reactZom</div>
//   )
// }

// export default reactZom
