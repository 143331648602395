import React from "react";
import { useState } from "react";
import { Modal, Form, Input, Button, notification, message } from "antd";
import { Col, Row } from "react-bootstrap";
import { POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BiLogoInstagram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { LiaWhatsapp } from "react-icons/lia";

function SellusModal(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        phoneNumber: values.phoneNumber,
        metalType: values.metalType,
        weight: values.weight,
        comments: values.comments,
        email: values.email,
      };
      const response: any = await POST(API.SELLUS, obj);
      if (response?.status === true) {
        message.success("succesfully added");
        setLoading(false);
        props.onClose();
      } else {
        message.error("");
      }
    } catch (err) {
      setLoading(false);
      Notifications["error"]({
        message: "Oops !",
        description: "Something gone wrong.",
      });
    }
  };
  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {contextHolder}
      <Modal
        title={<h2>Sell To Us</h2>}
        open={props.visible}
        onCancel={() => props.onClose()}
        footer={false}
        centered
        width={800}
      >
        <Form form={form} onFinish={onFinish}>
          <br />
          <Row>
            <Col sm="6">
              <p>
                Phone: <strong>{API.CONTACT_NUMBER}</strong>
                <br />
                Email: <strong>{API.CONTACT_MAIL}</strong>
              </p>
            </Col>
            <Col md="6">
              <div className="Payment-cards">
                <RiFacebookCircleLine
                  onClick={() =>
                    handleLinkClick("https://www.facebook.com/luckystargoldllc")
                  }
                  size={25}
                  color="#000"
                />
                <BiLogoInstagram
                  onClick={() =>
                    handleLinkClick(
                      "https://www.instagram.com/luckystargoldgcc/?hl=en"
                    )
                  }
                  size={25}
                  color="#000"
                />
                <FaXTwitter
                  onClick={() =>
                    handleLinkClick("https://twitter.com/Luckystargold")
                  }
                  size={22}
                  color="#000"
                />
                <PiYoutubeLogoLight size={24} color="#000" />
                <LiaWhatsapp
                  onClick={() => handleLinkClick("https://wa.me/+971565084747")}
                  size={24}
                  color="#000"
                />
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <div className="form-label">Phone Number</div>
              <Form.Item
                rules={[{ required: true, message: "Enter Phone Number" }]}
                name={"phoneNumber"}
              >
                <Input type="number" size="large" placeholder="Phone Number" />
              </Form.Item>
              <div className="form-label">Metal Type</div>
              <Form.Item
                rules={[{ required: true, message: "Enter Metal Type" }]}
                name={"metalType"}
              >
                <Input size="large" placeholder="Metal Type" />
              </Form.Item>
              <div className="form-label">Weight</div>
              <Form.Item
                rules={[{ required: true, message: "Enter Weight" }]}
                name={"weight"}
              >
                <Input size="large" placeholder="Weight" />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <div className="form-label">Email</div>
              <Form.Item
                rules={[{ required: true, message: "Enter Email" }]}
                name={"email"}
              >
                <Input type="email" size="large" placeholder="Email" />
              </Form.Item>

              <div className="form-label">Comments</div>
              <Form.Item
                rules={[{ required: true, message: "Enter Comments" }]}
                name={"comments"}
              >
                <Input.TextArea rows={4} size="large" placeholder="Comments" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}></Col>
            <Col sm={3} xs={6}>
              <Button
                onClick={() => props.onClose()}
                block
                size="large"
                style={{ height: 50 }}
              >
                Close
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button
                htmlType="submit"
                block
                size="large"
                type="primary"
                style={{ height: 50 }}
                loading={loading}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default SellusModal;
