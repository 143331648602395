import { Image, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { GET } from "../../utils/apiCalls";
import API from "../../config/API";

function Blog() {
  const { id } = useParams();
  const [blog, setBlog] = useState<any>([]);
  const [Notifications, contextHolder] = notification.useNotification();

  const getBlog = async () => {
    try {
      let url = API.FIND_ONE_BLOG + id;
      const response: any = await GET(url, null);

      if (response?.status) setBlog(response?.data);
      else {
        Notifications["error"]({
          message: "Failed to load Blog",
          description: response?.message,
        });
        setBlog([]);
      }
    } catch (err) {
      Notifications["error"]({
        message: "Failed to load Blog",
        description: "something went wrong..!",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlog();
  }, []);
  return (
    <>
    <div  className="Screen-box">
      {contextHolder}
      <Row className="mx-0">
        <Col md={6}>
          <div className="Blog-image1">
            <Image width={"100%"} alt="image1" src={blog?.image} />
          </div>
        </Col>
        <Col md={6} className="">
          <h1 className="Blog-details-txt1 BlogDetails-txt-bold">
            {blog?.title}
          </h1>
          <div className="Blog-details">
            <h4 style={{fontSize:"17px"}} className="BlogDetails-desc">{blog?.description}</h4>
          </div>
        </Col>

        {/* safe */}
        {/* <Col md={7}>
          <div className="Blog-details">
            <h1 className="Blog-details-txt1 BlogDetails-txt-bold">
              {blog?.title}
            </h1>
            <p className="BlogDetails-content">{blog?.content}</p>
            <h4 className="BlogDetails-desc">{blog?.description}</h4>
          </div>
        </Col> */}
      </Row>
    <div style={{padding:"10px"}} >
    <p  className="BlogDetails-content">{blog?.content}</p>
    </div>
    </div>

    </>
  );
}
export default Blog;
