import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDyPQSkF70Lr9WuKXCx-2vyDINDzfpqXDk",
  authDomain: "luckystar-ecommerce.firebaseapp.com",
  projectId: "luckystar-ecommerce",
  storageBucket: "luckystar-ecommerce.appspot.com",
  messagingSenderId: "359448742769",
  appId: "1:359448742769:web:f7c3c0c9ea519752b4c4d0",
  measurementId: "G-GGQX5NKVF2",
};
const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { Auth, GoogleProvide };
